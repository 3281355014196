// import FaceRecApp from '../assets/Proj1/Proj1_07face1.png'
import IacProject from '../assets/Proj0/iac.gif'
import FaceRecApp from '../assets/Proj1/Proj1_faceApp.gif'
import ShoppingApp from '../assets/Proj2/store4.gif'
import ShowApp from '../assets/Proj3/tablet.gif'
import PortfolioSite from '../assets/Proj4/portfolioSite.jpg'
import "../styles/Projects.css";


export const ProjectList = [
  {
    id: 1,
    name: 'IAC Project',
    image: IacProject,
    githublink: "https://github.com/abdilmajid/iac-project",
    demolink: "https://github.com/abdilmajid/iac-project"
  },
  {
    id: 2,
    name: 'Face Recognition App',
    image: FaceRecApp,
    githublink: "https://github.com/abdilmajid/faceRecognitionAppReact",
    demolink: "http://face.abdil.ca"
  },
  {
    id: 3,
    name: 'Online Store',
    image: ShoppingApp,
    githublink: "https://github.com/abdilmajid/react-OnlineStore",
    demolink: "http://store.abdil.ca"
  },
  {
    id: 4,
    name: 'Show Tracker Site',
    image: ShowApp,
    githublink: "https://github.com/abdilmajid/ShowTrackerSite",
    demolink: "https://abdilmajid.github.io/ShowTrackerSite/"
  },
  {
    id: 5,
    name: 'Portfolio Site',
    image: PortfolioSite,
    githublink: "https://github.com/abdilmajid/personalwebsite",
    demolink: "http://abdil.ca"
  }
]